










































































































@import "../less/vars.less";
@import "../less/mixins.less";

.LuSubNav {
	position: relative;

	&__row {
		display: flex;
	}
	&__ul {
		list-style: none;
	}

	&__li {
		list-style: none;
		display: flex;
	}
	&__li--isActive {
		&::after {
			position: absolute;
			top: 0.5rem; left: -1rem;
			content: "•";
			font-size: 1.5rem;
		}
	}

	&__a {
		position: relative;
		display: flex;
		flex-grow: 1;
		text-decoration: none;
		align-items: center;
		color: inherit;
		display: block;
	}
	&__a:hover {
		color: black;
	}
}
